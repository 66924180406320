import React, { FC, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '@pofd-front/storybook';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import styles from './search-result-page.module.scss';
import Coupon from '../components/coupon/coupon';
import CouponList from '../components/coupon-list/coupon-list';
import { RootState } from '../store';

import noCouponsLogo from '../images/no_coupons_logo.svg';
import useScrollSearch from '../shared/hooks/use-scroll-search';
import { COUPONS_ALL_ID } from '../utils';
import useSearchCoupons from '../shared/hooks/use-search-coupons';

interface ISearchResultPage {
  onGetCoupons: () => void;
}

const SearchResultPage: FC<ISearchResultPage> = ({
  onGetCoupons,
}) => {
  const [val, setVal] = useState('');
  const location = useLocation();
  const [btnSize, setBtnSize] = useState(false);
  const { scrollSearch, isLoading } = useScrollSearch();
  const rootEl = useRef(null);

  const { searchCoupons } = useSearchCoupons();

  const { posObjData } = useSelector(
    (state: RootState) => state.userPosition,
  );

  const { filteredCoupons } = useSelector(
    (state: RootState) => state.filteredCouponsList,
  );

  const { cityName, regionName, regionCode } = posObjData;

  const { couponList, accessKey } = useSelector(
    (state: RootState) => state.couponsAndCategoriesList,
  );

  useEffect(() => {
    if (
      localStorage.getItem('access_key_is_distributed') === 'true'
    ) {
      onGetCoupons();
    }
  }, []);

  useEffect(() => {
    setVal(
      decodeURIComponent(location.search).split('search_text=')[1],
    );
  }, [location]);

  useEffect(() => {
    (async () => {
      await searchCoupons({
        city_name: cityName,
        region_name: regionName !== undefined ? regionName : '',
        region_code: regionCode,
        page: '0',
        access_key: accessKey,
        size: '24',
        shopOrTitleName: decodeURIComponent(location.search)
          .split('search_text=')[1]
          .toLowerCase(),
        banner_id: String(COUPONS_ALL_ID),
      });
    })();
  }, [location, cityName, regionName, regionCode, searchCoupons]);

  const scrollHandler = () => {
    // @ts-ignore
    const coorEl = rootEl.current?.getBoundingClientRect().bottom;

    const scrollHeight = document.documentElement.clientHeight;
    if (scrollHeight >= coorEl) {
      scrollSearch(accessKey);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
    return () =>
      document.removeEventListener('scroll', scrollHandler);
  }, []);

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth > 768) {
        setBtnSize(true);
      } else {
        setBtnSize(false);
      }
    };

    onResize();

    window.addEventListener('resize', onResize);
  }, []);

  return (
    <div>
      <Helmet title="Результаты поиска">
        <meta
          name="keywords"
          content={`Скидки, Скидка, Кэшбэк, Карта, бесплатно, дней, 500, 5000, подарок, Доставка, Подарок, урока, ${val}`}
        />

        <meta name="description" content="Страница поиска" />
      </Helmet>
      <div className={styles['mt-4']}>
        {filteredCoupons?.length ? (
          <>
            <h5 className={`${styles.title}`}>
              Результаты поиска: {val}
            </h5>
            <ul
              ref={rootEl}
              className={`${styles.list} d-block d-sm-flex flex-wrap`}
            >
              {filteredCoupons!.map((item) => (
                <li className={`${styles.item}`} key={item.couponId}>
                  <Coupon data={item} />
                </li>
              ))}
            </ul>
            {isLoading && <p>Идет загрузка купонов...</p>}
          </>
        ) : (
          <div>
            <div className={styles['no-result-head']}>
              <div className={styles.logo}>
                <img src={noCouponsLogo} alt="no coupons" />
              </div>
              <div className={styles['no-result-desc']}>
                <div className={styles['no-coupons-title']}>
                  По вашему запросу ничего не найдено
                </div>
                <p>
                  Попробуйте написать по-другому или сократить запрос.
                </p>
                <div className="d-md-flex">
                  <Link to="/">
                    <Button
                      styleType="secondary"
                      product="ofd"
                      type="button"
                      label="Вернуться ко всем купонам"
                      size={btnSize ? 'medium' : 'large'}
                      onClick={() => null}
                      theme="common"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className={styles['no-result-main']}>
              <div className={styles.title}>Может заинтересовать</div>
              <CouponList
                cardsGet4Click={couponList.slice(0, 12)}
                isBanner={false}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchResultPage;
