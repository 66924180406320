import React, { FC, memo } from 'react';
import Overlay from '../overlay/overlay';

const Loader: FC = memo(() => (
  <div>
    <Overlay onClose={() => null} />
  </div>
));

Loader.displayName = 'Loader';

export default Loader;
