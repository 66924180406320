import React, { FC, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import styles from './app-footer.module.scss';
import logoImg from '../../images/icons/logo-pcheck.svg';
import { supportHref } from '../../shared/constants/constatnts';

const AppFooter: FC = () => {
  const [activeFooterFlag, setActiveFooterFlag] = useState(true);
  const [currentYear] = useState(new Date().getFullYear());
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/redirect_store') {
      setActiveFooterFlag(false);
    } else {
      setActiveFooterFlag(true);
    }
  }, [location.pathname]);

  return (
    <div>
      {activeFooterFlag ? (
        <div className={styles.footer}>
          <Container>
            <Row>
              <Col xs={12} md={4}>
                <div className={styles['md-mb-3']}>
                  <Link data-testid="footer-nav-link-1" to="/">
                    <img
                      className="m-auto m-md-0"
                      src={logoImg}
                      alt="podarochek"
                    />
                  </Link>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="text-center">
                  <a
                    data-testid="footer-link-1"
                    target="_blank"
                    href="/policy"
                    className="link"
                  >
                    Политика конфиденциальности
                  </a>
                  <div className={styles['md-mb-4']}>
                    <a
                      data-testid="footer-link-2"
                      className="link"
                      target="_blank"
                      href="/terms"
                    >
                      Пользовательское соглашение
                    </a>
                  </div>
                  <div className={styles.inner}>
                    <a
                      data-testid="footer-link-3"
                      target="_blank"
                      href="/feedback"
                      className="link"
                    >
                      Форма обратной связи
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="text-center text-md-end">
                  <p className={styles['mb-3']}>
                    © {currentYear} ООО «Эвотор ОФД» Все права
                    защищены. Москва, ул. Усачева, д. 33, стр. 1
                  </p>
                  <p className={styles['md-mb-3']}>
                    Партнерство:{' '}
                    <a
                      data-testid="footer-link-4"
                      className="link"
                      href={supportHref}
                    >
                      support@podarocheck.ru
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ) : null}
    </div>
  );
};

export default AppFooter;
