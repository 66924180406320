import React, { FC, memo, useEffect, useState } from 'react';
import { Button } from '@pofd-front/storybook';
import { Link, useLocation } from 'react-router-dom';
import HomePageBanner from '../components/home-page-banner/home-page-banner';
import SuccessConfirmIcon from '../images/icons/success_connect_cheque.svg';
import ErrorConfirmIcon from '../images/icons/error_coupon_404.svg';

import styles from './email-confirmed-page.module.scss';
import { supportHref } from '../shared/constants/constatnts';
import largeIphoneIcon from '../images/icons/home-page-banner/vertical.svg';
import smallIphoneIcon from '../images/icons/home-page-banner/horizontal.svg';

const EmailConfirmedPage: FC = memo(() => {
  const [emailConfirmedFlag, setEmailComfirmedFlag] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.search.includes('confirmed')) {
      setEmailComfirmedFlag(
        location.search.split('confirmed=')[1] === 'true',
      );
    }
  }, [location]);

  useEffect(() => {
    const onResize = () => {
      if (window.innerWidth < 480) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    onResize();

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div className={styles.wrap}>
      <div className={styles.desc}>
        <div>
          <img
            src={
              emailConfirmedFlag
                ? SuccessConfirmIcon
                : ErrorConfirmIcon
            }
            alt="success_icon"
          />
        </div>
        <div className={styles.title}>
          {emailConfirmedFlag
            ? 'Электронные чеки подключены'
            : 'Возникла ошибка'}
        </div>
        <div className={styles.subtitle}>
          {emailConfirmedFlag
            ? 'Теперь чеки поступают в ПодароЧек автоматически и доступны в мобильном приложении'
            : 'Попробуйте позже или обратитесь в службу поддержки:'}
        </div>
        {!emailConfirmedFlag && (
          <div className={styles.link}>
            <a className="link" href={supportHref}>
              support@podarocheck.ru
            </a>
          </div>
        )}
        <div className={styles.btn}>
          {isMobile && (
            <div className={styles.mobile_btn}>
              <a href="https://4310416.redirect.appmetrica.yandex.com/podarocheck.ru/?banner_id=&appmetrica_tracking_id=1109820820747750124">
                <Button
                  buttonStyles={{
                    border: '1.5px solid rgb(75, 161, 165)',
                    borderRadius: '.8rem',
                  }}
                  styleType="primary"
                  product="ofd"
                  type="button"
                  label="Открыть в приложении"
                  size="large"
                  onClick={() => null}
                  theme="common"
                />
              </a>
            </div>
          )}
          <Link to="/">
            <Button
              buttonStyles={{
                border: '1.5px solid rgb(75, 161, 165)',
                borderRadius: '.8rem',
              }}
              styleType="secondary"
              product="ofd"
              type="button"
              label="Остаться на сайте"
              size="large"
              onClick={() => null}
              theme="common"
            />
          </Link>
        </div>
      </div>
      {emailConfirmedFlag && (
        <div>
          <HomePageBanner
            qrLink="https://4310416.redirect.appmetrica.yandex.com/podarocheck.ru/?banner_id=&appmetrica_tracking_id=101014486583938964"
            title="Чеки хранятся в&nbsp;приложении"
            theme="default"
            subtitle={'История покупок\n в одном месте'}
            horizontalIconSrc={largeIphoneIcon}
            verticalIconSrc={smallIphoneIcon}
          />
        </div>
      )}
    </div>
  );
});

EmailConfirmedPage.displayName = 'EmailConfirmedPage';

export default EmailConfirmedPage;
