import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@pofd-front/storybook';
import largeIphoneIcon from '../../images/icons/home-page-banner/large_iphone.svg';
import smallIphoneIcon from '../../images/icons/home-page-banner/small_iphone.png';
import HomePageBanner from '../home-page-banner/home-page-banner';
import Coupon from '../coupon/coupon';
import { declensionNum } from '../../utils';
import { ICoupons } from '../../interfaces';

import styles from './coupon-list.module.scss';

interface ICouponList {
  cardsGet4Click: ICoupons[];
  isBanner: boolean;
}

interface IList {
  couponsList: ICoupons[];
}

const List: FC<IList> = ({ couponsList }) => (
  <ul className={`${styles.list} d-block d-sm-flex flex-wrap`}>
    {couponsList.map((item) => (
      <li className={`${styles.item}`} key={item.couponId}>
        <Coupon data={item} />
        {item.couponSameStore?.length ? (
          <div className={styles.btn}>
            <Link to={`/shop/${item.shop.id}`}>
              <Button
                styleType="borderless"
                rightIcon="arrowRight"
                iconStroke
                type="button"
                disabled={false}
                product="ofd"
                testId="authorization_info_modal_content-click-1"
                label={`Посмотреть все ${
                  item.couponSameStore.length + 1
                } ${declensionNum(item.couponSameStore.length + 1, [
                  'акция',
                  'акции',
                  'акций',
                ])}`}
                size="large"
                onClick={() => null}
                theme="common"
              />
            </Link>
          </div>
        ) : null}
      </li>
    ))}
  </ul>
);

const CouponList: FC<ICouponList> = ({
  cardsGet4Click,
  isBanner,
}) => {
  const [couponsList, setCouponsList] = useState<ICoupons[]>([]);
  const [otherCouponsList, setOtherCouponsList] = useState<
    ICoupons[]
  >([]);

  useEffect(() => {
    if (isBanner) {
      const newArr = [...cardsGet4Click];
      setCouponsList(newArr.splice(0, 12));
      setOtherCouponsList(newArr);
    } else {
      setCouponsList(cardsGet4Click);
    }
  }, [cardsGet4Click, isBanner]);

  return (
    <div className={styles['mt-5']}>
      {couponsList.length ? (
        <div>
          {isBanner ? (
            <div>
              <List couponsList={couponsList} />
              <HomePageBanner
                qrLink="https://4310416.redirect.appmetrica.yandex.com/podarocheck.ru/?banner_id=&appmetrica_tracking_id=100973818293539190"
                theme="ofd"
                title="Экономьте удобнее с&nbsp;приложением"
                subtitle="Скидки, акции и&nbsp;подарки всегда у&nbsp;вас в&nbsp;смартфоне"
                horizontalIconSrc={largeIphoneIcon}
                verticalIconSrc={smallIphoneIcon}
              />
              {!!otherCouponsList.length && (
                <List couponsList={otherCouponsList} />
              )}
            </div>
          ) : (
            <List couponsList={couponsList} />
          )}
        </div>
      ) : (
        <p>Купоны не найдены</p>
      )}
    </div>
  );
};

export default CouponList;
